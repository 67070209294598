import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";
import imgAuthor from "../../assets/image/jpeg/author.jpg";

const SectionStyled = styled(Section)``;

const Author = styled(Box)`
  min-width: 271px;
  max-width: 271px;
  min-height: 271px;
  max-height: 271px;
  border-radius: 500px;
  overflow: hidden;
  margin-bottom: 30px;
  @media ${device.lg} {
    margin-bottom: 0;
  }
`;

const Fact = () => (
  <>
    {/* <!-- Fact section 1 --> */}
    <SectionStyled bg="#f6f6f8">
      <Container>
        <Row>
          <Col xs="12" className="mb-5">
            <div className="text-center">
              <Title>Why Choose Napa Vertical?</Title>
            </div>
          </Col>
          <Col lg="4" className="offset-lg-1 mb-4 mb-lg-0">
            <Author>
              <img src={imgAuthor} alt="Author" />
            </Author>
          </Col>
          <Col lg="7" className="pr-lg-5">
            <div className="author-text">
              <Text variant="small" my={4}>
                In order to streamline business processes in a government
                facility environment, you need a bulletproof system that gives
                you the full picture of all operations in each building. Napa
                Vertical offers excellent cloud-based management software that
                lets you reduce resolution times and increase staff
                productivity. A user-friendly interface is easily adaptable and
                integrates seamlessly into your existing processes as well.
              </Text>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Fact;
